import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  ledger: [],
  tabJournal: 'Expense Journal',
  tab: 'expense',
  expense: [],
  expenseWithPaymentAll:[],
  expenseSetting: [],
  invoiceExpense: '',
  journalSetting: [],
  invoiceJournal: '',
  fixedSetting: [],
  invoiceFixed: '',
  SupplierPartyName: [],
  invoice: [],
  invoiceExp: [],
  nature: [],
  expenseEdit: [],
  expenseWithPaymenteEdit: [],
  journalExpense: [],
  journalInvoice: [],
  journalNature: [],
  journalExpenseEdit: [],
  journalLegder: [],
  fixExpense: [],
  fixNewExpense: [],
  fixedAssetAllIncomeTax: [],
  fixInvoice: [],
  fixNature: [],
  fixExpenseEdit: [],
  fixLegder: [],
  FixedAssetsNature: [],
  RateOfDepreciations: [],
  expenseSummary: null
};

const slice = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    setExpenseEdit(state, action) {
      state.expenseEdit = action.payload;
    },
    setExpenseWithPaymenteEdit(state, action) {
      state.expenseWithPaymenteEdit = action.payload;
    },
    setLedger(state, action) {
      state.ledger = action.payload;
    },
    
    setTab(state, action) {
      state.tab = action.payload;
    },

    setTabJournal(state, action) {
      state.tabJournal = action.payload;
    },

    setPdf(state, action) {
      state.pdf = action.payload;
    },
    setExpense(state, action) {
      state.expense = action.payload;
    },
    setExpenseWithPaymentAll(state, action) {
      state.expenseWithPaymentAll = action.payload;
    },
    setSupplierPartyName(state, action) {
      state.SupplierPartyName = action.payload;
    },
    setInvoice(state, action) {
      state.invoice = action.payload;
    },
    setExpInvoice(state, action) {
      state.invoiceExp = action.payload;
    },
    setNature(state, action) {
      state.nature = action.payload;
    },
    setJournalExpense(state, action) {
      state.journalExpense = action.payload;
    },
    setJournalInvoice(state, action) {
      state.journalInvoice = action.payload;
    },
    setJournalNature(state, action) {
      state.journalNature = action.payload;
    },
    setJournalExpenseEdit(state, action) {
      state.journalExpenseEdit = action.payload;
    },
    setJournalLegder(state, action) {
      state.journalLegder = action.payload;
    },
    setFixExpense(state, action) {
      state.fixExpense = action.payload;
    },
    setNewFixExpense(state, action) {
      state.fixNewExpense = action.payload;
    },
    setFixedAssetAllIncomeTax(state, action) {
      state.fixedAssetAllIncomeTax = action.payload;
    },
    setFixInvoice(state, action) {
      state.fixInvoice = action.payload;
    },
    setFixNature(state, action) {
      state.fixNature = action.payload;
    },
    setFixExpenseEdit(state, action) {
      state.fixExpenseEdit = action.payload;
    },
    setFixLegder(state, action) {
      state.fixLegder = action.payload;
    },
    setExpenseSetting(state, action) {
      state.expenseSetting = action.payload;
    },
    setInvoiceExpense(state, action) {
      state.invoiceExpense = action.payload;
    },
    setJournalSetting(state, action) {
      state.journalSetting = action.payload;
    },
    setInvoiceJournal(state, action) {
      state.invoiceJournal = action.payload;
    },
    setFixedSetting(state, action) {
      state.fixedSetting = action.payload;
    },
    setInvoiceFixed(state, action) {
      state.invoiceFixed = action.payload;
    },
    setFixedAssetsNature(state, action) {
      state.FixedAssetsNature = action.payload;
    },
    setRateOfDepreciations(state, action) {
      state.RateOfDepreciations = action.payload;
    },
    setExpenseSummary(state, action) {
      state.expenseSummary = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setLedger,
  setExpense,
  setExpenseWithPaymentAll,
  setSupplierPartyName,
  setInvoice,
  setExpInvoice,
  setNature,
  setExpenseEdit,
  setExpenseWithPaymenteEdit,
  setTab,
  setTabJournal,
  setJournalExpense,
  setJournalInvoice,
  setJournalExpenseEdit,
  setJournalNature,
  setJournalLegder,
  setFixExpense,
  setNewFixExpense,
  setFixedAssetAllIncomeTax,
  setFixInvoice,
  setFixLegder,
  setPdf,
  setFixExpenseEdit,
  setFixNature,
  setExpenseSetting,
  setInvoiceExpense,
  setJournalSetting,
  setInvoiceJournal,
  setFixedSetting,
  setInvoiceFixed,
  setFixedAssetsNature,
  setRateOfDepreciations,
  setExpenseSummary,
} = slice.actions;

export const getParty = () => async (dispatch) => {
  try {
    await axios.get('/expense/partyName').then((response) => dispatch(setLedger(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getExpenseSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios.get(`/expense/summary/EXP?start_date=${sd}&end_date=${ed}`).then((response) => dispatch(setExpenseSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getExpense = (sd,ed,p,r,s) => async (dispatch) => {
  try {
    await axios.get(`/expense/expenseAll?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`).then((response) => dispatch(setExpense(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true; 
};

export const getExpenseWithPaymentAll = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/expense/ExpenseWithPaymentAll?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setExpenseWithPaymentAll(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSupplierPartyName = (id) => async (dispatch) => {
  try {
    await axios.get(`/expense/SupplierPartyName`).then((response) => dispatch(setSupplierPartyName(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getNature = () => async (dispatch) => {
  try {
    await axios.get('/expense/nature').then((response) => dispatch(setNature(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoice = (id) => async (dispatch) => {
  try {
    await axios.get(`/expense/invoices?ledger_ID=${id}`).then((response) => dispatch(setInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

// check it getExpInvoice this api
export const getExpInvoice = (id) => async (dispatch) => { 
  try {
    await axios
      .get(`/expense/invoices?type=EXP&ledger_ID=${id}`)
      .then((response) => dispatch(setExpInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};


export const ExpenseWithPaymentEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/expense/ExpenseWithPayment?ID=${id}`).then((response) => dispatch(setExpenseWithPaymenteEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getExpenseEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/expense/expense?ID=${id}`).then((response) => dispatch(setExpenseEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getJournalNature = () => async (dispatch) => {
  try {
    await axios.get('/Journal/nature').then((response) => dispatch(setJournalNature(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getJournalInvoice = (id) => async (dispatch) => {
  try {
    await axios
      .get(`/Journal/invoices?type=EXP&ledger_ID${id}`)
      .then((response) => dispatch(setJournalInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getJournalParty = () => async (dispatch) => {
  try {
    await axios.get('/Journal/partyName').then((response) => dispatch(setJournalLegder(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getJournalExpenseEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/Journal/journal?ID=${id}`).then((response) => dispatch(setJournalExpenseEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getJournalExpense = (sd, ed, n, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/Journal/journalAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`)
      .then((response) => dispatch(setJournalExpense(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getFixNature = () => async (dispatch) => {
  try {
    await axios.get('/fixedAsset/nature').then((response) => dispatch(setFixNature(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getFixInvoice = (id) => async (dispatch) => {
  try {
    await axios
      .get(`/fixedAsset/invoices?type=EXP&ledger_ID${id}`)
      .then((response) => dispatch(setFixInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFixParty = () => async (dispatch) => {
  try {
    await axios.get('/fixedAsset/partyName').then((response) => dispatch(setFixLegder(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFixExpenseEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/fixedAsset/fixedAsset?ID=${id}`).then((response) => dispatch(setFixExpenseEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFixExpense = () => async (dispatch) => {
  try {
    await axios.get('/fixedAsset/fixedAssetAll').then((response) => dispatch(setFixExpense(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getNewFixExpense = (sd, ed) => async (dispatch) => {
  try {
    // await axios .get(`/ledger-partyAll?start=${startDate || ''}&end=${endDate || ''}`).then((response) => dispatch(setNewLedger(response.data)));
    await axios
      .get(`/fixedAsset/NewfixedAssetAll?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setNewFixExpense(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getFixedAssetAllIncomeTax = (year) => async (dispatch) => {
  // fixedAsset/fixedAssetAllIncomeTax?year=2002'
  try {
    await axios
      .get(`/fixedAsset/fixedAssetAllIncomeTax?year=${year}`)
      .then((response) => dispatch(setFixedAssetAllIncomeTax(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExpenseSetting = () => async (dispatch) => {
  try {
    await axios.get('/expense/ExpenseWithPaymentSettings').then((response) => dispatch(setExpenseSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getinvoiceExpense = () => async (dispatch) => {
  try {
    await axios.get('/expense/ExpenseWithPayment_VoucherNo').then((response) => dispatch(setInvoiceExpense(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getJournalSetting = () => async (dispatch) => {
  try {
    await axios.get('/Journal/JournalSettings').then((response) => dispatch(setJournalSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getinvoiceJournal = () => async (dispatch) => {
  try {
    await axios.get('/Journal/Journal_VoucherNo').then((response) => dispatch(setInvoiceJournal(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFixedSetting = () => async (dispatch) => {
  try {
    await axios.get('/fixedAsset/FixedAssetSettings').then((response) => dispatch(setFixedSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getinvoiceFixed = () => async (dispatch) => {
  try {
    await axios.get('/fixedAsset/FixedAsset_VoucherNo').then((response) => dispatch(setInvoiceFixed(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFixedAssetsNature = () => async (dispatch) => {
  try {
    await axios.get('/fixedAsset/FixedAssetsNature').then((response) => dispatch(setFixedAssetsNature(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getRateOfDepreciations = (id, cb) => async (dispatch) => {
  try {
    await axios.get(`/fixedAsset/RateOfDepreciations?categoryID=${id}`).then((response) => {
      cb?.(response?.data);
      dispatch(setRateOfDepreciations(response.data));
    });
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
