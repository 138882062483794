import React from 'react'
import {  Button } from '@mui/material';
import {setRun} from '../../../redux/slices/salesprop'
import { useDispatch } from '../../../redux/store';

const GuidePopover = () => {
    const dispatch = useDispatch()
  return (
    <>
    <Button sx={{fontSize: { xs: '10px', md: '12.4px' } }} variant='contained' size='small' onClick={()=>dispatch(setRun(true))}>Take a Tour</Button>
    </>
  )
}

export default GuidePopover