// export const currency = [
//     { id: '1', label: 'INR ( ₹ )' },
//     { id: '2', label: 'DOLLAR ( $ )' },
// ];
export const currency=[
    { sym:'Lek', label: 'ALL' },
    { sym:' دج', label: 'DZD' },
    { sym:'Kz', label: 'AOA' },
    { sym:' $ ', label: 'ARS' },
    { sym:'Դ', label: 'AMD' },
    { sym:' $ ', label: 'AUD' },
    { sym:' € ', label: 'EUR' },
    { sym:' ₼ ', label: 'AZN' },
    { sym:' $ ', label: 'BBD' },
    { sym:' د.ب', label: 'BHD' },
    { sym:' Br ', label: 'BYN' },
    { sym:' $ ', label: 'BMD' },
    { sym:'$b ', label: 'BOB'},
    { sym:' KM ', label: 'BAM' },
    { sym:'P ', label: 'BWP' },
    { sym:' R$ ', label: 'BRL' },
    { sym:' лв ', label: 'BGN' },
    { sym:' $ ', label: 'CVE' },
    { sym:'៛', label: 'KHR' },
    { sym:' FCFA', label: 'XAF' },
    { sym:' $ ', label: 'CAD' },
    { sym:' $ ', label: 'KYD' },
    { sym:' $ ', label: 'CLP' },
    { sym:' ¥ ', label: 'CNY' },
    { sym:' $ ', label: 'COP' },
    { sym:' FC ', label: 'CDF' },
    { sym:' ₡ ', label: 'CRC' },
    { sym:' kn ', label: 'HRK' },
    { sym:'Kč', label: 'CZK' },
    { sym:'kr', label: 'DKK' },
    { sym:'RD$', label: 'DOP' },
    { sym:' $ ', label: 'USD' },
    { sym:' £ ', label: 'EGP' },
    { sym:'Br', label: 'ETB' },
    { sym:' $ ', label: 'FJD' },
    { sym:'₾', label: 'GEL' },
    { sym:' ¢ ', label: 'GHS' },
    { sym:' £ ', label: 'GIP' },
    { sym:'Q', label: 'GTQ' },
    { sym:'£', label: 'GBP' },
    { sym:'$', label: 'GYD' },
    { sym:'L', label: 'HNL' },
    { sym:'$', label: 'HKD' },
    { sym:'Ft', label: 'HUF' },
    { sym:'kr', label: 'ISK' },
    {sym:' ₹ ', label: 'INR' },
    { sym:'Rp', label: 'IDR' },
    { sym:'ع.د', label: 'IQD' },
    { sym:'£', label: 'IMP' },
    { sym:'₪', label: 'ILS' },
    { sym:'F.CFA', label: 'XOF' },
    { sym:'J$', label: 'JMD' },
    { sym:'¥', label: 'JPY' },
    { sym:'JD', label: 'JOD' },
    { sym:'лв', label: 'KZT' },
    { sym:'KSh', label: 'KES' },
    { sym:'₩', label: 'KRW' },
    { sym:'د.ك', label: 'KWD' },
    { sym:'лв', label: 'KGS' },
    { sym:'₭', label: 'LAK' },
    { sym:'£', label: 'LBP' },
    { sym:' ل.د', label: 'LYD' },
    { sym:'Fr', label: 'CHF' },
    { sym:'$', label: 'MOP' },
    { sym:'Ar', label: 'MGA' },
    { sym:'MK', label: 'MWK' },
    { sym:'RM', label: 'MYR' },
    { sym:'Rf', label: 'MVR' },
    { sym:'UM', label: 'MUR' },
    { sym:'₨', label: 'MUR' },
    { sym:'$', label: 'MXN' },
    { sym:'L', label: 'MDL' },
    { sym:'₮', label: 'MNT' },
    { sym:'د.م', label: 'MAD' },
    { sym:'MT', label: 'MZN' },
    { sym:'K', label: 'MMK' },
    { sym:'$', label: 'NAD' },
    { sym:'$', label: 'NZD' },
    { sym:'C$', label: 'NIO' },
    { sym:'₦', label: 'NGN' },
    { sym:' ден', label: 'MKD' },
    { sym:'kr', label: 'NOK' },
    { sym:' ﷼, ' , label: 'OMR' },
    { sym:' ₨ ' , label: 'PKR' },
    { sym:' B/. ' ,label: 'PAB' },
    { sym:'K', label: 'PGK' },
    { sym:' Gs ' ,label: 'PYG' },
    { sym:'S/.' ,label: 'PEN' },
    { sym:'₱' ,label: 'PHP' },
    { sym:'zł' ,label: 'PLN' },
    { sym:'﷼ ',label: 'QAR' },
    { sym:'lei',label: 'RON' },
    { sym:'₽',label: 'RUB' },
    { sym:'RF',label: 'RWF' },
    { sym:'$',label: 'XCD' },
    { sym:'﷼',label: 'SAR' },
    { sym:'Дин.',label: 'RSD' },
    { sym:'$',label: 'SGD' },
    { sym:'R',label: 'ZAR' },
    { sym:'Rs',label: 'LKR' },
    { sym:'E',label: 'SZL' },
    { sym:'kr',label: 'SEK' },
    { sym:'NT$',label: 'TWD' },
    { sym:'SM',label: 'TJS' },
    { sym:'TSh',label: 'TZS' },
    { sym:'฿',label: 'THB' },
    { sym:'TT$',label: 'TTD' },
    { sym:'د.ت',label: 'TND' },
    { sym:'₺',label: 'TRY' },
    { sym:'m', label: 'TMT' },
    { sym:'USh',label: 'UGX' },
    { sym:'₴',label: 'UAH' },
    { sym:' د.إ',label: 'AED' },
    { sym:'$U',label: 'UYU' },
    { sym:'лв',label: 'UZS' },
    { sym:'Bs',label: 'VEF' },
    { sym:'₫',label: 'VND' },
    { sym:'ZK',label: 'ZMW' },
    { sym:'Z$',label: 'ZWD' },
  ];