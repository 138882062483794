import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  accountMode: [],
  partyName: [],
  tab: 'Payment',
  invoiceList: [],
  payment: [],
  setPayment: [],
  paymentEdit: null,
  paymentSetting: {},
  voucherNo: null,
  paymentSummary:null
};

const slice = createSlice({
  name: 'reciept',
  initialState,
  reducers: {
    setAccountMode(state, action) {
      state.accountMode = action.payload;
    },
    setPaymentSetting(state, action) {
      state.paymentSetting = action.payload;
    },
    setVoucherNo(state, action) {
      state.voucherNo = action.payload;
    },
    setPartyName(state, action) {
      state.partyName = action.payload;
    },
    setInvoiceList(state, action) {
      state.invoiceList = action.payload;
    },
    setPayment(state, action) {
      state.payment = action.payload;
    },
    setPaymentAll(state, action) {
      state.paymentAll = action.payload;
    },
    setPaymentEdit(state, action) {
      state.paymentEdit = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setPaymentSummary(state, action) {
      state.paymentSummary = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setAccountMode,
  setPartyName,
  setInvoiceList,
  setPayment,
  setPaymentAll,
  setPaymentEdit,
  setTab,
  setPaymentSetting,
  setVoucherNo,
  setPaymentSummary
} = slice.actions;


export const getPaymentSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/payment/PaymentExpenseSummary?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setPaymentSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getVoucherNo = (typ, trans) => async (dispatch) => {
  try {
    await axios
      .get(`/payment/voucherNo?typ=${typ}&transType=${trans}`)
      .then((response) => dispatch(setVoucherNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPaymentSetting = () => async (dispatch) => {
  try {
    await axios.get('/payment/setting').then((response) => dispatch(setPaymentSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAccountMode = () => async (dispatch) => {
  try {
    await axios.get('/payment/accountMode').then((response) => dispatch(setAccountMode(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPartyName = () => async (dispatch) => {
  try {
    await axios.get('/payment/partyName').then((response) => dispatch(setPartyName(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceList = (id) => async (dispatch) => {
  try {
    await axios
      .get(`/payment/invoices?type=PAY&ledger_ID=${id}`)
      .then((response) => dispatch(setInvoiceList(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPayment = (sd, ed, n,r,s) => async (dispatch) => {
  try {
    await axios.get(`/payment/paymentAll?start_date=${sd}&end_date=${ed}&pagination=${n}&records=${r}&search=${s}`).then((response) => dispatch(setPayment(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPaymentAll = (sd,ed,p,r,sh) => async (dispatch) => {
  try {
    await axios.get(`/payment/NewpaymentAll?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${sh}`).then((response) => dispatch(setPaymentAll(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPaymentEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/payment/payment?ID=${id}`).then((response) => dispatch(setPaymentEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
