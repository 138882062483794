import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import masterdataReducer from './slices/masterdata';
import branchesReducer from './slices/branches';
import teamReducer from './slices/team';
import salesReducer from './slices/sales';
import organisationReducer from './slices/organisations';
import salespropReducer from './slices/salesprop';
import purchaseReducer from './slices/purchase';
import jobProjectReducer from './slices/jobproject';
import recieptReducer from './slices/reciept';
import paymentReducer from './slices/payment';
import expenseReducer from './slices/expense';
import taxReducer from './slices/tax';
import dashboardReducer from './slices/dashboard';
import reportReducer from './slices/report';
import employeeReducer from './slices/employee';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({
  // mail: mailReducer,
  // chat: chatReducer,
  // calendar: calendarReducer,
  // kanban: kanbanReducer,
  user: userReducer,
  masterdata: masterdataReducer,
  branches: branchesReducer,
  team: teamReducer,
  sales: salesReducer,
  salesprop: salespropReducer,
  purchase: purchaseReducer,
  jobproject: jobProjectReducer,
  reciept: recieptReducer,
  payment: paymentReducer,
  expense: expenseReducer,
  tax: taxReducer,
  dashboard: dashboardReducer,
  report: reportReducer,
  organisation: organisationReducer,
  employee: employeeReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
