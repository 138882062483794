import { useNavigate , useLocation} from 'react-router';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { styled, useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Typography, Card, Button, Tabs, Tab, Tooltip, IconButton, Stack, Alert,Container,AppBar,Toolbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Page from '../components/Page';
// import Logo from '../components/Logo';
import { RHFTextField, FormProvider } from '../components/hook-form';
import axios from '../utils/axios';
import navConfig from './Offerhomebtn';
import Homecolor from './Offerhomecolor';
import Phonesize from './Offerphonesize';
import Iconify from '../components/Iconify';
import useOffSetTop from '../hooks/useOffSetTop';
import useIsMountedRef from '../hooks/useIsMountedRef';
import useResponsive from '../hooks/useResponsive';
import useAuth from '../hooks/useAuth';
import { PATH_AUTH } from '../routes/paths';
import PoPMessageForm from './PoPMessageForm';
import { HEADER } from '../config';

function getCurrentYear() {
  return new Date().getFullYear();
}

export default function Offer() {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  const isDesktop = useResponsive('up', 'lg');

  const { enqueueSnackbar } = useSnackbar();
  const offerSchema = Yup.object().shape({
    customerName: Yup.string().required('This is required field'),
    quoteMobile: Yup.string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(10, 'Too Short!')
      .max(10, 'Too Long!'),
    quoteEmail: Yup.string().email('Email must be a valid email address'),
    quoteMessage: Yup.string().required('This is required field'),
  });

  const defaultValues = {
    customerName: '',
    quoteEmail: '',
    quoteMobile: '',
    quoteMessage: '',
  };

  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,
    transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
      height: HEADER.MAIN_DESKTOP_HEIGHT,
    },
  }));

  const ToolbarShadowStyle = styled('div')(({ theme }) => ({
    left: 0,
  }));

  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const methods = useForm({
    resolver: yupResolver(offerSchema),
    defaultValues,
  });
  const isMountedRef = useIsMountedRef();

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const [errorMessage, setErrorMessage] = useState();

  const onSubmit = async (data) => {
    try {
      const { quoteMobile, quoteEmail } = data;
      if (quoteEmail === '' && quoteMobile === null) {
        setErrorMessage('Email or Mobile both can not be empty');
        throw new Error('Email or Mobile both can not be empty');
      } else {
        await axios.post('/postQuote', data);
        enqueueSnackbar('create success!');
      }
      reset();
    } catch (error) {
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };
  const [show, setshow] = useState(false);

  // const [read, setread] = useState(false);

  const [see, setsee] = useState(false);
  const abc = true;
  const [state, setState] = useState(0);

  const handleClick = async (subscriptionType, subscription) => {
    if (isAuthenticated) {
      let localSubscription = parseInt(subscription, 10);
      if (subscriptionType === 1) {
        localSubscription += 1;
      }
      if (window.location.origin !== 'https://www.startupkhata.com') {
        localSubscription = 11;
      }
      const { data } = await axios.post('subscriptions/get-token', {
        subscription: localSubscription,
      });
      if (data.encryption) {
        window.location.href = data.encryption;
      }
    } else {
      const pack = {
        subscriptionType,
        subscription,
      };
      const packs = JSON.stringify(pack);
      localStorage.setItem('package', packs);
      navigate(PATH_AUTH.register);
    }
  };

  return (
    
    
    <Page>
    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
 
  </Page>
  );
}
